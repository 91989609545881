<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                {{$t('Confirmar Cotización')}}
            </template>
            <template v-slot:content>
                <div class="p-field p-col-12">
                    {{$t('Información Básica')}}
					<DataTable :value="[formData.datos]" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
							:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
						<Column field="Datos" :header="$t('Número ID')">
							<template #body="slotProps">
								{{slotProps.data.code}}
							</template>
						</Column>
						<Column field="Datos" :header="$t('Nombre Completo')">
							<template #body="slotProps">
								{{slotProps.data.nombre}}
							</template>
						</Column>
                       
					</DataTable>
                </div>
                <div class="p-field p-col-12">
                    {{$t('Tipo de Póliza')}}: {{formData.datos.tipopoliza.nombre}}
                </div>
            </template>
            <template v-slot:footer>
                <div class="p-grid p-nogutter p-justify-between">
                    <Button :label="$t('Anterior')" @click="prevPage()" icon="pi pi-angle-left" />
                    <Button :label="$t('Cotizar')" @click="complete()" icon="pi pi-check" iconPos="right" class="p-button-success"/>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import API from "@/service/ApiConsulta";
import { useI18nPlugin } from '@unify/vuex-i18n';

export default {
    props: {
        formData: Object
    },
    data () {
        return {
			size: '60vw',
			i18n: null,
        }
    },
	created() {
		this.i18n = useI18nPlugin();
	},
    methods: {
        prevPage() {
            this.$emit('prev-page', {pageIndex: 4});
        },
        complete() {
            this.$store.commit('Loading');
            const Consulta = new API('Cotizacion');
            Consulta.Procesar('Incendio',{		
				caso: 	'INS',
				datos: this.formData.datos,
				propiedad: this.formData.propiedad,
				descripciones: this.formData.descripciones,
				inventario: this.formData.inventario,
				continuidad: this.formData.continuidad,
                leng: this.i18n.locale(),
			}).then(response => {
				//this.$store.state.error =response;
				if (response.result) {
                    this.$toast.add({
                        severity:'success',
                        summary: 'Éxito',
                        detail: 'Información Procesada', 
                        life: 3000
                    });
                    this.$router.push({ path: '/myquotation'});
				} else {
					this.$toast.add({
                        severity:'warn',
                        summary: 'Advertencia',
                        detail: 'No fue procesada la solicitud', 
						life: 3000
                    });
				}
                this.$store.commit('Loading');
			});
        },
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},

    }
}
</script>